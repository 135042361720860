import {
  type ClientLoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { useMemo } from 'react';

import { useOnboardingAnalytics } from '../analytics/onboarding';
import { GameCenterContextProvider } from '../components/Game/GameCenter';
import {
  DefaultGamePackCardBadges,
  GamePackCard,
  type GamePackCardStyles,
} from '../components/Game/GamePack/GamePackCard';
import { GameCoverClip } from '../components/GamePackCover/GamePackCoverPres';
import { OnboardingPageLayout } from '../components/Onboarding/OnboardingLayout';
import { useMyOrganizationFeatureChecker } from '../components/Organization/hooks/organization';
import { useNavigateToSubscriptionChange } from '../components/Product/utils';
import config from '../config';
import { GamePackContextProvider } from '../pages/GamePack/Context';
import { GamePackCardBottomAccessory } from '../pages/GamePack/GamePackCardBottomAccessory';
import { apiService } from '../services/api-service';
import { type GamePack } from '../types/game';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);

  const tagId = url.searchParams.get('tag-id') || config.misc.favoriteTagId;
  const tag = (await apiService.tag.getTag(tagId)).data.tag;
  const packs = await apiService.gamePack
    .getGamePacksByTagId(tag.id, false, 6)
    .next();

  return {
    tag,
    packs,
  };
}

export function Component() {
  const { tag, packs } = useLoaderData<typeof clientLoader>();
  const navigate = useNavigate();
  const analytics = useOnboardingAnalytics();
  const featureChecker = useMyOrganizationFeatureChecker();
  const navigateUpgradePage = useNavigateToSubscriptionChange();

  const styles = useMemo<GamePackCardStyles>(() => {
    return {
      size: 'w-70',
    };
  }, []);

  const handleClick = (pack: GamePack) => {
    analytics.trackOnboardingOndGameClicked({
      gamePackId: pack.id,
      gamePackName: pack.name,
    });

    if (!featureChecker.canAccessGamePackForOnd(pack)) {
      navigateUpgradePage();
      return;
    }

    navigate(`/my-venue/${pack.id}`);
  };

  return (
    <OnboardingPageLayout progress={80} className='bg-library-2023-07'>
      <h2 className='text-2xl font-medium text-tertiary'>
        Which {tag.name} experience would you like to try?
      </h2>
      <div className='mt-15 grid grid-cols-3 gap-y-10 gap-x-3'>
        <GameCenterContextProvider>
          <GamePackContextProvider embed={true} pageType='public'>
            {packs.map((pack) => (
              <GamePackCard
                key={pack.id}
                gamePack={pack}
                styles={styles}
                onClick={handleClick}
                badges={<DefaultGamePackCardBadges gamePack={pack} />}
                bottomAccessory={<GamePackCardBottomAccessory pack={pack} />}
              />
            ))}
          </GamePackContextProvider>
        </GameCenterContextProvider>
      </div>

      <GameCoverClip id='game-cover-clip' />
    </OnboardingPageLayout>
  );
}
